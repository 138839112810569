<template>
  <div id="Contact">
    <div class="row">
      <div class="card_list">
        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> business </span>
            <h4 class="sub_title_font">Company Info</h4>
          </div>
          <div class="card_body info">
            <div class="input_group col_6">
              <label class="sub_content_font">Company Name</label>
              <p class="content content_font">
                {{ company_data.company_name.Content }}
              </p>
            </div>
            <div class="input_group col_6">
              <label class="sub_content_font">LINE ID</label>
              <p class="content content_font">
                {{ company_data.company_line_id.Content }}
              </p>
            </div>
            <div class="input_group col_6">
              <label class="sub_content_font">Comapny Tel</label>
              <p class="content content_font">
                {{ company_data.company_phone.Content }}
              </p>
            </div>
            <div class="input_group col_6">
              <label class="sub_content_font">Company Email</label>
              <p class="content content_font">
                {{ company_data.company_email.Content }}
              </p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">Service Hours</label>
              <p class="content content_font">
                {{ company_data.company_time.Content }}
              </p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">Company Address</label>
              <p class="content content_font">
                {{ company_data.company_address.Content }}
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> supervisor_account </span>
            <h4 class="sub_title_font">Contact</h4>
            <p class="sub_content_font">Require*</p>
          </div>
          <div class="card_body">
            <div class="input_group col_6">
              <label class="sub_content_font">Name*</label>
              <input
                type="text"
                :class="{ error: GetError('name') != '' }"
                v-model="form.name"
                name="name"
              />
              <p class="error">{{ GetError('name') }}</p>
            </div>
            <div class="input_group col_6">
              <label class="sub_content_font">Phone*</label>
              <input
                type="text"
                :class="{ error: GetError('phone') != '' }"
                v-model="form.phone"
                name="phone"
              />
              <p class="error">{{ GetError('phone') }}</p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">Email</label>
              <input type="text" v-model="form.email" name="email" />
              <p class="error">{{ GetError('email') }}</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> insert_comment </span>
            <h4 class="sub_title_font">Message</h4>
            <p class="sub_content_font">Require*</p>
          </div>
          <div class="card_body">
            <div class="input_group col_12">
              <label class="sub_content_font">Title*</label>
              <!-- <input
                type="text"
                :class="{ error: GetError('title') != '' }"
                v-model="form.title"
                name="comment_title"
              /> -->
              <select
                v-model="form.title"
                name="comment_title"
                :class="{ error: GetError('title') != '' }"
              >
                <option>Inquiry / Buy Product</option>
                <option>Business Collaboration</option>
                <option>Other</option>
              </select>
              <p class="error">{{ GetError('title') }}</p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">Message*</label>
              <textarea
                :class="{ error: GetError('comment') != '' }"
                v-model="form.comment"
                name="comment"
              ></textarea>
              <p class="error">{{ GetError('comment') }}</p>
            </div>
          </div>
        </div>
      </div>
      <button class="submit_btn content_font" @click="Validate">SUBMIT</button>
    </div>
  </div>
</template>

<script>
import DataValid from '../common/data_valid';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'Contact',
  mixins: [DataValid],
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        title: 'Inquiry / Buy Product',
        comment: '',
      },
      errors: [],
      meta_data: null,
    };
  },
  methods: {
    Validate() {
      this.errors = [];
      let valid_array = [
        {
          type: 'name',
          msg: this.validName(this.form.name),
        },
        {
          type: 'phone',
          msg: this.validPhone(this.form.phone),
        },
        {
          type: 'title',
          msg: this.validNotEmpty(this.form.title),
        },
        {
          type: 'comment',
          msg: this.validNotEmpty(this.form.comment),
        },
      ];

      valid_array.forEach((item) => {
        if (item.msg != true) {
          this.errors.push(item);
        }
      });

      if (this.errors.length <= 0) {
        this.SubmitData();
      }
    },
    async SubmitData() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/mail', 'post', {
        Data: {
          Name: this.form.name,
          Email: this.form.email,
          Phone: this.form.phone,
          Title: this.form.title,
          Content: this.form.comment,
          ProductID: [],
        },
      });
      if (response != 'error') {
        this.$store.commit('SetLoading', false);
        this.$store.commit('SetDialog', {
          status: true,
          content: 'Message Has Been Sent!',
        });
      }
    },
    GetError(val) {
      let error = this.errors.filter((item) => item.type == val);
      if (error.length > 0) {
        return error[0].msg;
      } else {
        return '';
      }
    },
  },
  created() {
    this.meta_data = GetMetaData('Contact', '', '');
  },
  computed: {
    company_data() {
      return this.$store.state.company_info;
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
